<template>
  <div>
    <IbTabs />
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form
        class="px-1 flex-shrink-0 d-flex align-items-center flex-wrap"
        style="gap: 8px;"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <b-form-group>
              <b-form-input
                v-model="filter.full_name"
                :placeholder="$t('full_name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <b-form-group>
              <b-form-input
                v-model="filter.account"
                :placeholder="$t('account')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <b-form-group>
              <b-form-input
                v-model="filter.mobile"
                :placeholder="$t('mobile_number')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <b-form-group>
              <b-form-input
                v-model="filter.email"
                :placeholder="$t('email')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <b-form-group>
              <div class="d-flex">
                <flat-pickr
                  ref="register-date"
                  v-model="filter.date_from"
                  class="form-control"
                  name="to-expiration"
                  :placeholder="$t('from-date')"
                  autocomplete="nope"
                  :clearable="true"
                />
                <b-button
                  style="height: 38px;"
                  class="mx-1"
                  @click="clearDate('date_from')"
                >
                  <feather-icon
                    icon="XIcon"
                    class=""
                    size="15"
                  />
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <b-form-group>
              <div class="d-flex">
                <flat-pickr
                  ref="register-date"
                  v-model="filter.date_to"
                  class="form-control"
                  name="to-expiration"
                  :placeholder="$t('to-date')"
                  autocomplete="nope"
                  :clearable="true"
                />
                <b-button
                  style="height: 38px;"
                  class="mx-1"
                  @click="clearDate('date_to')"
                >
                  <feather-icon
                    icon="XIcon"
                    class=""
                    size="15"
                  />
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="12"
            lg="12"
            class="d-flex align-items-center mb-1"
          >
            <b-button
              class="mx-1 mt-1 w-100"
              style="height: 40px;width: 100px;"
              variant="success"
              @click="getData"
            >
              {{ $t('search') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col cols="12">
              <b-table
                class="demo-table"
                no-border-collapse
                responsive
                striped
                hover
                :fields="accountsfields"
                :items="accounts"
              >
                <template #cell(country)="data">
                  {{ data.item.country.name }} (+{{ data.item.country.precode }})
                </template>
                <template #cell(created_at)="data">
                  {{ moment(data.item.created_at ).format('YYYY/MM/DD') }}
                </template>
              </b-table>
            </b-col>

          </b-row>
          <b-row class="justify-content-center">
            <b-pagination
              v-model="pagination.page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              align="fill"
              size="sm"
              class="mt-2 mb-1"
              @input="getData()"
            />
          </b-row>
        </b-card>
      </b-col>
    </b-overlay>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BButton,
  BIconCheckLg,
  BIconXLg,
} from 'bootstrap-vue'
import { required } from '@validations'
import moment from 'moment-jalaali'
import flatPickr from 'vue-flatpickr-component'
import PannelIbApis from '@/modules/IB/services/apis'
import IbTabs from '../components/ibTabs.vue'

const IB = new PannelIbApis()
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    IbTabs,
    BButton,
    flatPickr,
    BIconCheckLg,
    BIconXLg,
  },
  data() {
    return {
      show: true,
      required,
      moment,
      accounts: [],
      filter: {
        account: '',
        full_name: '',
        platform: '',
        mobile: '',
        email: '',
        user_id: '',
        date_from: '',
        date_to: '',
      },
      pagination: {
        page: 1,
        per_page: 10,
        total: 0,
      },
      accountsfields: [
        { key: 'full_name', label: this.$t('full_name') },
        { key: 'country', label: this.$t('country') },
        { key: 'mobile', label: this.$t('mobile') },
        { key: 'email', label: this.$t('email') },
        { key: 'account', label: this.$t('account') },
        { key: 'platform', label: this.$t('platform') },
        { key: 'group', label: this.$t('group') },
        { key: 'leverage', label: this.$t('leverage') },
        { key: 'created_at', label: this.$t('created_at') },
      ],
    }
  },

  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.show = true
      IB.demoAccounts({ ...this.filter, ...this.pagination }).then(res => {
        this.accounts = res.data.results.data
        this.pagination.page = res.data.results.pagination.current_page
        this.pagination.total = res.data.results.pagination.total
      }).finally(() => {
        this.show = false
      })
    },
    clearDate(filterFiled) {
      this.filter[filterFiled] = ''
    },
  },

}
</script>
  <style scoped lang="scss">
  .demo-table::v-deep .table td{
    padding: 5px 10px !important;
  }
  </style>
